<template>
  <div class="text-white modal modal-sm">
    <div class="px-4 py-5 bg-gray-700 border-b border-gray-800 sm:px-6">
      <h3 class="text-lg font-medium leading-6 text-slate-100">Delete Layer</h3>
    </div>

    <div class="px-8 py-4 modal-content bg-zinc-900">
      <form @submit.prevent="submit">
        <div class="mb-6">
          <p class="text-sm">
            Are you sure you want to delete the following layer?
          </p>

          <div class="p-2 mt-2 text-sm border border-gray-300 rounded">
            <fa-icon icon="photo-film" size="sm" class="mr-2 text-blue-500" />
            {{ layer.name }}
          </div>
          <!--
          <div class="mt-2 text-sm italic text-red-500" v-if="showFolderWarning">
            This will also delete {{ childCount }} child items
          </div> -->
        </div>
        <div class="flex items-center justify-end btn-group">
          <button class="text-white btn" type="button" @click="$emit('close')">
            Cancel
          </button>
          <button class="btn negative" type="button" @click.prevent="submit">
            <fa-icon icon="trash-can" class="mr-2" /> Delete
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LayerDelete',

  props: {
    layer: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      form: new SparkForm({})
    }
  },

  computed: {},

  methods: {
    attachKeyboardListeners() {
      document.addEventListener('keyup', this.handleKeyboardEvents)
    },

    detachKeyboardListeners() {
      document.removeEventListener('keyup', this.handleKeyboardEvents)
    },

    handleKeyboardEvents(event) {
      let activeElementType = document.activeElement.nodeName

      // We only listen for input if the activeElement is NOT an input, or text area
      if (
        !(activeElementType === 'INPUT' || activeElementType === 'TEXTAREA')
      ) {
        switch (event.code) {
          case 'Enter':
            this.submit()
            break
        }
      }
    },

    submit() {
      this.$bus.$emit('layer:delete', { layer: this.layer })
      this.$emit('close')
    }
  },

  mounted() {
    this.attachKeyboardListeners()
  },

  beforeDestroy() {
    this.detachKeyboardListeners()
  }
}
</script>
